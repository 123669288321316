import Navbar from '../components/nav.jsx'
import Footer from '../components/footer.jsx'
import Hero from '../components/hero.jsx'
import NewsletterSignup from '../components/newsletter-signup.jsx'
import Contact from '../components/contact.jsx'

function About() {

    return ( 
     <div className='max-container'>
          <Navbar/>
          <main className='about-content'>
               <div className="overlay primary"></div>
                <Hero title="ABOUT SOMMET" cta="LEARN MORE" url="#text">
                    <p>
                        Step into a world where we've cracked the code to business brilliance. Ever wondered what it would be like to have your most tedious tasks vanish into thin air? Curious about the secret sauce behind businesses that seem to run on autopilot? Look no further.
                    </p>
               </Hero> 
               <div id="text" className="text">
                <h2 data-aos="fade-up" data-aos-duration="700">Our Master Plan:</h2>
                <p data-aos="fade-up" data-aos-duration="700">At SOMMET INDUSTRIES, our master plan revolves around innovation, automation, and the relentless pursuit of excellence. Our journey begins with building cutting-edge automation tools and services, meticulously designed to alleviate the common, repetitive tasks that can bog down businesses. By doing so, we not only empower our clients with more time and resources but also pave the way for a brighter future.</p>
                <p data-aos="fade-up" data-aos-duration="700">We're not stopping there. Our vision extends to a future where robotic autonomous processes become the norm, relieving production demands on companies and reshaping supply chain management by bringing more manufacturing back to the United States. With a commitment to replacing low-skilled jobs with high-skilled ones, we're poised to lead the charge towards a more prosperous and equitable workforce.</p>
                <p data-aos="fade-up" data-aos-duration="700">But that's just the beginning. We're investing in concrete 3D printing technology, leveraging the cost-efficiency of materials and labor to revolutionize the housing market. Our goal? To make the American dream attainable for all by offering 3D-printed homes at an astonishingly affordable price of approximately $50,000.</p>
                <p data-aos="fade-up" data-aos-duration="700">Education is at the core of our values. We're creating a free accredited education platform that empowers individuals to earn STEM degrees, unlocking their full potential, increasing their earning potential, and enhancing their life satisfaction.</p>
                <h2 data-aos="fade-up" data-aos-duration="700">Our Recent Focus:</h2>
                <p data-aos="fade-up" data-aos-duration="700">Under the banner of Sommet Technologies, our recent mission revolves around empowering entrepreneurs to run their businesses with ease and efficiency. We achieve this through the development of innovative software tools, designed to automate business processes, streamline operations, and provide entrepreneurs with the time and resources they need to focus on their core functions.</p>
                <p data-aos="fade-up" data-aos-duration="700">In the short term, we're dedicated to developing software tools that enable entrepreneurs to automate their business processes with minimal staff. Our aim is to offer a suite of solutions that tackle repetitive tasks like data entry, invoicing, and customer service, freeing up valuable resources.</p>
                <p data-aos="fade-up" data-aos-duration="700">As we grow, our vision expands into automation manufacturing technology, using state-of-the-art robotics, hardware, and software to make production more efficient and cost-effective. We're committed to providing customized solutions for businesses of all sizes, helping them optimize operations and boost profitability.</p>
                <p data-aos="fade-up" data-aos-duration="700">At Sommet Technologies, we believe in technology's power to create positive change in the world. By arming entrepreneurs with the tools they need to succeed, we're contributing to a brighter, more sustainable future. Our ultimate goal is to be at the forefront of innovation, leading the way in providing entrepreneurs and businesses with the solutions they need to reach their full potential.</p>
                <p data-aos="fade-up" data-aos-duration="700">Welcome to the future. Welcome to SOMMET AUTOMATION.</p>
               </div>
               <NewsletterSignup/>  
               <Contact />  
          </main>
          <Footer />
     </div>
     );
}
 
export default About;