import React from 'react';
import template from '../assets/new-wp-template-logo.png'
import custom from '../assets/custom-solutions.png'

function FeaturedProduct() {

    return (
        <div id='products' className='container'>
            <h2 data-aos="fade-up" data-aos-duration="700">Your <span className='highlight'>Problems</span>,<br></br> Our <span className='highlight'>Solutions</span>.</h2>
            <div data-aos="fade-up" data-aos-duration="700"  className="featured-product">
                <div data-aos="fade-up" data-aos-duration="700" className="featured-wrap">
                    <img data-aos="fade-up" data-aos-duration="700" className='featured-image' src={template} alt="" />
                    <div className="featured-text">
                        <h3 data-aos="fade-up" data-aos-duration="700">Generate reports for WordPress site templates</h3>
                        <p data-aos="fade-up" data-aos-duration="700">With the WP Template Report Generator Chrome extension, you can gain valuable insights into your site's structure and easily identify which templates are used for each page.</p>
                        <a data-aos="fade-up" data-aos-duration="700"  className="btn" href="https://chrome.google.com/webstore/detail/wp-template-report-genera/kpfpomgllokecbkeicefbhnakpfpaacn?hl=en&authuser=0" target='_blank' without rel="noreferrer">Add to Chrome</a>
                    </div>
                </div>
                <div data-aos="fade-up" data-aos-duration="700" className="featured-wrap">
                    <img data-aos="fade-up" data-aos-duration="700" className='featured-image' src={custom} alt="" />
                    <div className="featured-text">
                        <h3 data-aos="fade-up" data-aos-duration="700">Explore Custom Solutions</h3>
                        <p data-aos="fade-up" data-aos-duration="700">Explore custom solutions to your unique problems. Contact us to learn more about how we can create the perfect autonomous process to serve you and your business needs.</p>
                        <a data-aos="fade-up" data-aos-duration="700"  className="btn" href="/contact" without rel="noreferrer">Contact</a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FeaturedProduct;