import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Navbar from '../components/nav.jsx';
import Footer from '../components/footer.jsx';
import NewsletterSignup from '../components/newsletter-signup.jsx';
import { createClient } from 'contentful';
import ReactMarkdown from 'react-markdown';

const SPACE_ID = process.env.REACT_APP_CONTENTFUL_SPACE_ID;
const ACCESS_TOKEN = process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN;

const client = createClient({
  space: SPACE_ID,
  accessToken: ACCESS_TOKEN
});

function fetchBlogPostDetail(postId) {
  return client.getEntry(postId);
}

function BlogPostDetail() {
  const { postId } = useParams();
  const [post, setPost] = useState(null);

  useEffect(() => {
    fetchBlogPostDetail(postId).then(response => {
      setPost(response.fields);
    }).catch(error => {
      console.error('Error fetching blog post details:', error);
    });
  }, [postId]);

  if (!post) return <p>Loading...</p>;

  return (
    <div className="max-container post-page">
      <Navbar />
      <main className="post">
        <div className="blog-post">
          <img src={post.image.fields.file.url} alt={post.image.fields.title} />
          <h1>{post.title}</h1>
          <div className='date'>
            {post.date}<span>|</span>
            {post.category.toUpperCase()}
          </div>
          <ReactMarkdown>{post.body}</ReactMarkdown>
          <p className='credit'>{post.credit.fields.credit}</p>
        </div>
        <NewsletterSignup/> 
      </main>
      <Footer />
    </div>
  );
}

export default BlogPostDetail;
