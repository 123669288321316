import Navbar from '../components/nav.jsx'
import Footer from '../components/footer.jsx'
import Hero from '../components/hero.jsx'
import NewsletterSignup from '../components/newsletter-signup.jsx'
import Contact from '../components/contact.jsx'

function Privacy() {

    return ( 
     <div className='max-container'>
          <Navbar/>
          <main className='privacy-content'>
               <div className="overlay primary"></div>
                <Hero title="SOMMET PRIVACY" cta="LEARN MORE" url="#text">
                    <p>
                        Privacy Policy. Last Updated: 9/23/2023
                    </p>
               </Hero> 
               <div className="text" id="text">
                <h2 data-aos="fade-up" data-aos-duration="700">1. Introduction</h2>
                <p data-aos="fade-up" data-aos-duration="700">Welcome to Sommet Automation! At Sommet Automation, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you visit our website and use our services.</p>
                <h2 data-aos="fade-up" data-aos-duration="700">2. Information We Collect</h2>
                <p data-aos="fade-up" data-aos-duration="700">2.1. <span>Personal Information:</span> We may collect personal information, such as your name and email address when you sign up for our newsletter or contact us through our website.</p>
                <p data-aos="fade-up" data-aos-duration="700">2.2. <span>Automatically Collected Information:</span> When you visit our website, we may collect certain information automatically, such as your IP address, browser type, device information, and usage data. We use cookies and similar tracking technologies to collect this data.</p>
                <h2 data-aos="fade-up" data-aos-duration="700">3. How We Use Your Information</h2>
                <p data-aos="fade-up" data-aos-duration="700">3.1. <span>To Provide Services:</span> We use your personal information to deliver the services you request, such as sending newsletters and responding to inquiries.</p>
                <p data-aos="fade-up" data-aos-duration="700">3.2. <span>To Improve Our Services:</span> We analyze usage data to enhance our website and services, ensuring a better experience for our users.</p>
                <p data-aos="fade-up" data-aos-duration="700">3.3. <span>Marketing and Communication:</span> With your consent, we may send you marketing communications about our products, services, and updates.</p>
                <h2 data-aos="fade-up" data-aos-duration="700">4. Information Sharing</h2>
                <p data-aos="fade-up" data-aos-duration="700">We do not sell, rent, or trade your personal information to third parties. However, we may share your information with trusted service providers and affiliates who assist us in delivering our services and improving our website.</p>
                <h2 data-aos="fade-up" data-aos-duration="700">5. Security</h2>
                <p data-aos="fade-up" data-aos-duration="700">We implement industry-standard security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction.</p>
                <h2 data-aos="fade-up" data-aos-duration="700">6. Your Choices</h2>
                <p data-aos="fade-up" data-aos-duration="700">You can opt out of receiving marketing communications from us at any time by following the unsubscribe instructions in our emails or by contacting us directly.</p>
                <h2 data-aos="fade-up" data-aos-duration="700">7. Third-Party Links</h2>
                <p data-aos="fade-up" data-aos-duration="700">
                Our website may contain links to third-party websites. Please note that we are not responsible for the privacy practices of these websites, and we encourage you to review their privacy policies.
                </p>
                <h2 data-aos="fade-up" data-aos-duration="700">8. Changes to this Privacy Policy</h2>
                <p data-aos="fade-up" data-aos-duration="700">
                We may update this Privacy Policy periodically to reflect changes in our practices and services. We will notify you of any significant updates through our website or via email.
                </p>
                <h2 data-aos="fade-up" data-aos-duration="700">9. Contact Us</h2>
                <p data-aos="fade-up" data-aos-duration="700">If you have any questions or concerns about this Privacy Policy or your personal information, please contact us.</p>
               </div>
               <NewsletterSignup/>  
               <Contact />  
          </main>
          <Footer />
     </div>
     );
}
 
export default Privacy;