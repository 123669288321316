import Navbar from '../components/nav.jsx'
import Footer from '../components/footer.jsx'
import ContactForm from '../components/contact-form.jsx';

function ContactPage() {

    return ( 
     <div className='max-container contact'>
          <Navbar />
                <main className='contact-content'>
                    <div className='overlay primary'>
                        <div className="text">
                            <h2>Connect with Us</h2>
                            <p>At Sommet Automation, we're passionate about empowering businesses like yours. Whether you're launching a new venture or looking to streamline your existing business, our team is eager to assist you. Reach out today, and let's begin building your path to success.</p>
                        </div>
                        <ContactForm />
                    </div>
                </main>
          <Footer />
     </div>
     );
}
 
export default ContactPage;