import React, { useState } from 'react';
import Navbar from '../components/nav.jsx'
import Footer from '../components/footer.jsx'
import Hero from '../components/hero.jsx'
import NewsletterSignup from '../components/newsletter-signup.jsx'
import Contact from '../components/contact.jsx'
import FaqItem from '../components/faq.jsx'; 

function Faqs() {
    const [expandedItem, setExpandedItem] = useState(null); // State to track the expanded item
      // Define an array of FAQ items
      const faqItems = [
        {
          question: 'What is Sommet Technologies?',
          answer: 'Sommet Technologies is a subsidiary focused on developing innovative software tools to automate business processes and streamline operations.'
        },
        {
          question: 'How can your software tools benefit entrepreneurs?',
          answer: 'Our tools enable entrepreneurs to automate tedious tasks like data entry, invoicing, and customer service, freeing up time and resources.'
        },
        {
            question: 'Do you customize solutions for businesses?',
            answer: 'Yes, we provide customized solutions tailored to the needs of businesses of all sizes.'
          },
        {
          question: 'What is your long-term vision for Sommet Industries?',
          answer: 'We aim to expand into robotic autonomous processes and concrete 3D printing technology to revolutionize supply chain management and the housing market.'
        },
        {
          question: 'Do you work with affiliates?',
          answer: 'Yes, we partner with companies that share our values and focus on innovative technology.'
        },
        {
          question: 'How do you ensure the privacy and security of my personal information?',
          answer: 'We implement industry-standard security measures to protect your personal information from unauthorized access or alteration.'
        },
        {
          question: 'Can I opt out of marketing communications?',
          answer: 'Yes, you can unsubscribe at any time by following the instructions in our emails.'
        },
        {
          question: 'Do you offer resources on automation and AI?',
          answer: 'Yes, we regularly publish research articles and tips in areas like AI in Education, Natural Language Processing, and the impact of Automation on Manufacturing.'
        },
        {
          question: 'How can I sign up for the newsletter?',
          answer: 'You can sign up for our newsletter through our website for free automation resources and tips.'
        },
        {
          question: 'What types of information do you collect automatically?',
          answer: 'We may collect information like your IP address, browser type, device information, and usage data.'
        },
        {
          question: 'What is the role of AI in your services?',
          answer: 'AI plays a crucial role in our services, especially in automating administrative tasks and personalizing learning on our education platform.'
        },
        {
          question: 'Who should I contact for more information?',
          answer: 'For any questions or concerns, you can contact us through our website.'
        }
      ];
      

  const handleExpand = (question) => {
    // Toggle the expanded state for the clicked item
    setExpandedItem((prevExpandedItem) =>
      prevExpandedItem === question ? null : question
    );
  };

    return ( 
     <div className='max-container'>
          <Navbar/>
          <main className='faqs-content'>
               <div className="overlay primary"></div>
                <Hero title="SOMMET FAQS" cta="LEARN MORE" url="#faq-list">
                    <p>
                        We thought you might ask.. We have answers.
                    </p>
               </Hero> 
               <div className="faqs-container" id="faq-list">
                  {/* Render the FAQ items */}
                  {faqItems.map((item, index) => (
                    <FaqItem
                      key={index}
                      question={item.question}
                      answer={item.answer}
                      expandedIndex={expandedItem}
                      onExpand={handleExpand}
                    />
                  ))}
                </div>
               <NewsletterSignup/>  
               <Contact />  
          </main>
          <Footer />
     </div>
     );
}
 
export default Faqs;