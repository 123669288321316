import React, { useState, useEffect } from 'react';
import { createClient } from 'contentful';

const SPACE_ID = process.env.REACT_APP_CONTENTFUL_SPACE_ID;
const ACCESS_TOKEN = process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN;

const client = createClient({
  space: SPACE_ID,
  accessToken: ACCESS_TOKEN
});

function FeaturedBlogs() {
  const [featuredBlogs, setFeaturedBlogs] = useState([]);

  useEffect(() => {
    client.getEntries({
      content_type: 'blogPost',
      'fields.category': 'guides',
      order: '-sys.createdAt',
      limit: 3
    }).then(response => {
      setFeaturedBlogs(response.items);
    });
  }, []);

  return (
    <div className='container'>
      <div id="featured-blogs" className="featured-blogs">
        <h2 data-aos="fade-up" data-aos-duration="700">Curious About Automation?</h2>
        <p data-aos="fade-up" data-aos-duration="700" className='heading'>Read our <span>guides</span> and become an expert</p>
        <div className="feature-wrap">
          {featuredBlogs.map(blog => (
            <div className="featured-blog" key={blog.sys.id}>
              <h3 data-aos="fade-up" data-aos-duration="700" className="heading">{blog.fields.title}</h3>
              <p data-aos="fade-up" data-aos-duration="700" className="excerpt">{blog.fields.body.substring(0, 200)}...</p>
              <a data-aos="fade-up" data-aos-duration="700" href={`/blog/post/${blog.sys.id}`} className="btn">Read more</a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FeaturedBlogs;
