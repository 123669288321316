import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import Navbar from '../components/nav.jsx';
import Footer from '../components/footer.jsx';
import BlogPost from '../components/blog-post.jsx';
import Hero from '../components/hero.jsx';
import NewsletterSignup from '../components/newsletter-signup.jsx';
import Contact from '../components/contact.jsx';
import { createClient } from 'contentful';

const SPACE_ID = process.env.REACT_APP_CONTENTFUL_SPACE_ID;
const ACCESS_TOKEN = process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN;

const client = createClient({
  space: SPACE_ID,
  accessToken: ACCESS_TOKEN
});

function fetchBlogPosts(category) {
  const query = {
    content_type: 'blogPost', 
  };

  if (category && category !== 'all') {
    query['fields.category'] = category;
  }

  return client.getEntries(query);
}


function BlogPage() {
  const { category } = useParams();
  const [blogPosts, setBlogPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    fetchBlogPosts(category)
      .then(response => {
        setBlogPosts(response.items);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error fetching blog posts:', error);
        setIsLoading(false); // also set loading to false in case of an error to not block the UI
      });
  }, [category]);
  
  
  const navigate = useNavigate();

  const handleCategoryChange = (newCategory) => {
    navigate(`/blog/${newCategory}`);
    window.scrollTo(0, 0);
  };

  return (
    <div className="max-container">
      <Navbar />
      <main className="blog-content">
        <div className="overlay primary"></div>
        <Hero title={`SOMMET ${category === 'all' ? 'BLOG' : category.toUpperCase()}`} cta="Read now" url="#content">
          <p>
            Explore our latest {`${category === 'all' ? 'blog posts' : category.toUpperCase()}`}:
          </p>
        </Hero>
        <div id="content" className="heading" data-aos="fade-up" data-aos-duration="700">
          <div className="category-options">
            <button className={`btn outline${category === 'all' ? ' active' : ''}`} onClick={() => handleCategoryChange('all')}>All</button>
            <button className={`btn outline${category === 'research' ? ' active' : ''}`} onClick={() => handleCategoryChange('research')}>Research</button>
            <button className={`btn outline${category === 'guides' ? ' active' : ''}`} onClick={() => handleCategoryChange('guides')}>Guides</button>
          </div>
          <h3 className='category' data-aos="fade-up" data-aos-duration="700">{category === 'all' ? 'All Posts' : `${category}`}</h3>
          <div className="blog-posts" data-aos="fade-up" data-aos-duration="700">
          {isLoading ? 
            <p>Loading...</p> :
            blogPosts.length ? blogPosts.map(post => (
              <Link key={post.sys.id} to={`/blog/${category}/${post.sys.id}`}>
                <BlogPost post={post} />
              </Link>
            )) : <p className='no-posts'>No posts found in this category.</p>
          }
        </div>

        </div>
        <NewsletterSignup />
        <Contact />
      </main>
      <Footer />
    </div>
  );
}

export default BlogPage;
