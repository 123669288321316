import React from 'react';
import PlusIcon from '../assets/plus.svg';

function FaqItem({ question, answer, expandedIndex, onExpand }) {
  const isExpanded = expandedIndex === question; // Check if this item is expanded

  const toggleExpand = () => {
    onExpand(question); 
  };

  return (
    <div className={`faq-item ${isExpanded ? 'expanded' : ''}`}>
      <h3 data-aos="fade-up" data-aos-duration="700" className="faq-question">{question}</h3>
      {isExpanded ? (
        <p className="faq-answer">{answer}</p>
      ) : (
        <button className="faq-toggle" onClick={toggleExpand}>
          <img src={PlusIcon} alt="Expand Icon"></img>
        </button>
      )}
    </div>
  );
}

export default FaqItem;
