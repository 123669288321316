import React from 'react';

function truncateText(text, maxLength) {
    if (!text) return ''; 
    if (text.length <= maxLength) return text;
    return text.substr(0, maxLength) + '...';
}

function BlogPost({ post }) {
    if (!post) return null;

    const maxContentLength = 100; 
    const truncatedContent = truncateText(post.fields.content, maxContentLength);

    return (
        <div className="blog-post">
            <img src={post.fields.image.fields.file.url} alt={post.fields.title} />
            <h3>{post.fields.title}</h3>
            <p>{post.fields.date}</p>
            <p>{truncatedContent}</p>
        </div>
    );
}

export default BlogPost;
