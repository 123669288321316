import React, { useEffect, useState } from 'react';
import GlitchButton from './../components/buttons';
import { HashLink } from 'react-router-hash-link';
import background1 from '../assets/ny-office.png'
import background2 from '../assets/india-office.png'
import background3 from '../assets/desert-office.png'
import mobileBackground1 from '../assets/ny-office-mobile.png'
import mobileBackground2 from '../assets/india-office-mobile.png'
import mobileBackground3 from '../assets/desert-office-mobile.png'

function Contact() {
    const [backgroundImage, setBackgroundImage] = useState('');

    useEffect(() => {
        // Determine if the user is on a mobile device based on viewport width
        const isMobile = window.innerWidth <= 768;

        // Array of background image paths based on device type
        const backgroundImages = isMobile
            ? [mobileBackground1, mobileBackground2, mobileBackground3]
            : [background1, background2, background3];

        // Select a random image
        const randomIndex = Math.floor(Math.random() * backgroundImages.length);
        const randomBackgroundImage = backgroundImages[randomIndex];

        // Set the selected image as background
        setBackgroundImage(`url(${randomBackgroundImage})`);
    }, []);

    return (
        <section className="contact" style={{ backgroundImage: backgroundImage }}>
            <div className="overlay secondary"></div>
            <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="600" className="content">
                <h2 data-aos="fade-up" data-aos-duration="1500" data-aos-delay="600">
                    <span data-aos="fade-up" data-aos-duration="1500" data-aos-delay="600">Let us supercharge</span>
                    <span data-aos="fade-up" data-aos-duration="1500" data-aos-delay="600">your growth with</span>
                    <span data-aos="fade-up" data-aos-duration="1500" data-aos-delay="600">cutting-edge</span>
                    <span data-aos="fade-up" data-aos-duration="1500" data-aos-delay="600">automation tools</span>
                    <span data-aos="fade-up" data-aos-duration="1500" data-aos-delay="600">and solutions.</span>
                </h2>
                <HashLink to="/contact"><GlitchButton text="Automate, Innovate, Elevate Your Business" /></HashLink>
            </div>
        </section>
    );
}

export default Contact;