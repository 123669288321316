

import React from 'react';

function GlitchButton({ href, text }) {
    return (
        <a href={href} className="btn-star">
            <span className="top_left"></span>
                <span className="top_right"></span>
                    <span className="title">
                        {text}
                    </span>
                <span className="bottom_left"></span>
            <span className="bottom_right"></span>
        </a>
    );
}

export default GlitchButton;