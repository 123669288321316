import Navbar from '../components/nav.jsx'
import Footer from '../components/footer.jsx'
import Hero from '../components/hero.jsx'

function fourOhfour() {

    return ( 
     <div className='max-container'>
          <Navbar/>
          <main className='fourOhfour-content'>
               <div className="overlay primary"></div>
                <Hero title="Lost? Head back home." cta="SOMMET HOME" url="/">
               </Hero> 
          </main>
          <Footer />
     </div>
     );
}
 
export default fourOhfour;