import React from 'react';
import HubspotForm from 'react-hubspot-form';

function ContactForm() {

    return (
        <div data-aos="fade-up" data-aos-duration="700" className='container' id="contact-form">
            <h3 data-aos="fade-up" data-aos-duration="700" className='heading'>Get in touch <br/> Let us know how we can help.</h3>
            <HubspotForm
                portalId="43612065"
                formId="38b5c59f-e5c0-4e26-983d-1238208f81bf"
            />
        </div>
    );
}

export default ContactForm;