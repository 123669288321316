import React from 'react';
import GlitchButton from './buttons.jsx';

function Hero({ title, children, cta, url }) {
    return (
        <div className="hero">
            <div data-aos="fade-in" data-aos-duration="1500" data-aos-delay="300" className="hero-text">
                <h1 data-aos="fade-in" data-aos-duration="1500" data-aos-delay="300">{title}</h1>
                <div data-aos="fade-in" data-aos-duration="1500" data-aos-delay="300" className="hero-description">
                    {children}
                </div>
                <GlitchButton href={url} text={cta} />
            </div>
        </div>
    );
}

export default Hero;
