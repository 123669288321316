import Navbar from '../components/nav.jsx'
import Footer from '../components/footer.jsx'
import Hero from '../components/hero.jsx'
import NewsletterSignup from '../components/newsletter-signup.jsx'
import Contact from '../components/contact.jsx'
import JonSommet from '../assets/channel.jpg'

function Affiliates() {

    return ( 
     <div className='max-container'>
          <Navbar/>
          <main className='affiliates-content'>
               <div className="overlay primary"></div>
                <Hero title="SOMMET AFFILIATES" cta="LEARN MORE" url="#affiliates-grid">
                    <p>
                        At Sommet, we work exclusively with the best companies that share our values. <br/> We want to drive the world forward with innovative technology and seek that same drive in others. Check out some of our affiliates below.
                    </p>
               </Hero> 
               <div className="affiliates-grid" id="affiliates-grid">
                    <a href='https://www.youtube.com/channel/UC0DfJMEOlTu8KuBR9vdE05A' target="_blank" rel="noreferrer" className="affiliate">
                         <img src={JonSommet} alt="Jon Sommet YouTube" />
                         <span>Jon Sommet</span>
                    </a>
               </div>
               <NewsletterSignup/>  
               <Contact />  
          </main>
          <Footer />
     </div>
     );
}
 
export default Affiliates;