import React, { useState, useEffect, useRef } from 'react';
import { HashLink } from 'react-router-hash-link';
import logo from '../assets/logo-letter.svg';

function Nav() {
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    if (menuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [menuOpen]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const imgRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0 && imgRef.current) {
        imgRef.current.classList.add('active');
      } else if (imgRef.current) {
        imgRef.current.classList.remove('active');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav className='navbar'>
      <div className="container">
        <span>
          <HashLink className='link' to="/#" onClick={closeMenu}>
            <img ref={imgRef} className='home-link' src={logo} alt="" />
          </HashLink>
        </span>
        <div className={`menu-icon ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <ul className={`${menuOpen ? 'show-menu' : ''}`}>
          <li><HashLink className='link mobile' to="/" onClick={closeMenu}>Home</HashLink></li>
          <li><HashLink className='link' to="/#products" onClick={closeMenu}>Products</HashLink></li>
          <li><HashLink className='link' to="/blog/research" onClick={closeMenu}>Research</HashLink></li>
          <li><HashLink className='link' to="/blog/guides" onClick={closeMenu}>Guides</HashLink></li>
          <li><HashLink className='link' to="/blog/all" onClick={closeMenu}>Blog</HashLink></li>
          <li><HashLink className='link' to="#newsletter-signup" onClick={closeMenu}>Join</HashLink></li>
          <li><HashLink className='link' to="/about" onClick={closeMenu}>About</HashLink></li>
          <li><HashLink className='link' to="/contact" onClick={closeMenu}>Contact</HashLink></li>
        </ul>
      </div>
    </nav>
  );
}

export default Nav;
