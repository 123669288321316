import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, { useEffect } from "react";
import Home from "./pages/home";
import About from "./pages/about";
import Affiliates from "./pages/affiliates";
import Privacy from "./pages/privacy";
import ContactPage from './pages/contact';
import BlogPage from "./pages/blog-lander";
import FourOhFour from "./pages/404";
import Faqs from "./pages/faqs";
import Post from './pages/post';
import './sass/main.scss';
import AOS from "aos";
import "aos/dist/aos.css";
import ScrollRestoration from "./components/scroll-resotration";

function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <>
     <BrowserRouter>
      <ScrollRestoration />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/affiliates" element={<Affiliates />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/blog/:category" element={<BlogPage />} />
        <Route path="/blog/:categoryId/:postId" element={<Post />} /> 
        <Route path="/faqs" element={<Faqs />} /> 
        <Route path="*" element={<FourOhFour />} />
      </Routes>
     </BrowserRouter>
    </>
  );
}

export default App;
