import Navbar from '../components/nav.jsx'
import Footer from '../components/footer.jsx'
import Hero from '../components/hero.jsx'
import FeaturedProduct from '../components/featured-product.jsx'
import FeaturedBlog from '../components/featured-blogs.jsx'
import NewsletterSignup from '../components/newsletter-signup.jsx'
import Contact from '../components/contact.jsx'

function Home() {

    return ( 
     <div className='max-container'>
          <Navbar/>
          <main className='home-content'>
               <div className="overlay primary"></div>
               <Hero title="SOMMET INDUSTRIES" cta="Automate, Innovate, Elevate Your Business" url="#products">
                    <p>
                         Streamline tasks, supercharge growth, and achieve new heights with our cutting-edge automation tools. <br/>
                         From detailed <a className='hero-link' href="https://chrome.google.com/webstore/detail/wp-template-report-genera/kpfpomgllokecbkeicefbhnakpfpaacn?hl=en&authuser=0">WordPress site reporting </a>
                         to <a className='hero-link' href="#products">Custom Automation Solutions</a>, we're here to automate solutions to your business needs.
                    </p>
               </Hero>
               <FeaturedProduct />  
               <FeaturedBlog/>  
               <p className='container form-copy'>Join our newsletter for a peek into the world of business automation and innovative technology. Get insights, updates, and exclusive content from SOMMET INDUSTRIES, where we're shaping a smarter, more efficient future.</p>
               <NewsletterSignup/>  
               <Contact />  
          </main>
          <Footer />
     </div>
     );
}
 
export default Home;